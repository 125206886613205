<!--
 * @Author: 月魂
 * @Date: 2022-11-11 10:04:22
 * @LastEditTime: 2022-11-12 12:10:16
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \food-show\src\views\Taste\Video.vue
-->
<template>
  <div class="box">
    <div class="cont">
      <Navigation :title="this.$t('taste.thousand.title')" />
      <img src="../../assets/temp/taste.png" alt="" class="img top-bg">
      <img src="../../assets/temp/taste-pc.png" alt="" class="img top-bg pc">
      <!-- 千年陕菜部分 -->
      <div class="part">
        <div class="item" v-for="item in this.$t('taste.thousand.cont')" :key="item.id">
          <video controls :poster="domain + item.posterUrl">
            <source :src="domain + item.videoUrl">
          </video>
          <div class="des">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  name: 'TasteVideo',
  components: {
    Navigation
  },
  data() {
    return {
      title: '',
      domain: ''
    }
  },
  mounted() {
    this.domain = this.$t('domain')
    this.title = this.$t('taste.title')
    this.wxShare(this.$t('title'), this.$t('taste.title'))
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #06062B;
  position: relative;
  padding-bottom: 20px;
  color: #fff;
  .cont {
    max-width: 1200PX;
    margin: auto;
  }
  .top-bg {
    position: absolute;
    top: 0;
    left: 0;
  }
  .pc {
    display: none;
  }
  @media (min-width: 1000PX) {
    .top-bg {
      display: none;
    }
    .pc {
      display: block;
    }
  }
  .part {
    padding: 0 16px;
    max-width: 1200PX;
    margin: auto;
    margin-top: 8px;
    position: relative;
    z-index: 1;
    .item {
      width: 100%;
      border-radius: 15px;
      margin-bottom: 16px;
      overflow: hidden;
      background: #111135;
      padding-bottom: 8px;
      .des {
        margin-top: 8px;
        padding-left: 16px;
        font-size: 12px;
        color: #fff;
      }
    }
    
    video, img {
      display: block;
      width: 100%;
      border-radius: 15px;
    }
  }
}
</style>
